body {
background-color: #222 ;
	/* animation: gradient 15s ease infinite; */
	height: 100vh;
  text-align: center;}



/*.map{
  margin-top: 30px;
  
}*/
.App{
  background-color: #333;
  margin-left:auto;
  margin-right:auto;
  max-width: 600px;
  margin-top: 70px;
  margin-bottom: 50px;
}