.card{
    image-rendering: auto;
    display: flex;
    flex-direction: column;
    background-color: #a2c2c2;
    margin-left:auto;
    margin-right:auto;
    max-width: 600px;
    align-items:center;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 25px;
    cursor: pointer;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
   
    background: #fff;
    animation: gradient 5s ease infinite;
}
    

