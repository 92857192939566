.search-box{

border: none;
outline: none;
padding: 10px;
width: 400px;
border-radius: 5px;
line-height: 30px;
margin-bottom: 30px;
margin-top: 50px;
}

h1{
    /* margin-top: 200px ;
    margin-bottom: 0px; */
    font-size: 25px;
    color: #b38bff;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
   }

.search {
    margin-bottom: 30px;
    margin-top: 200px;
    padding-top: 20px;
}